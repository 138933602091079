import React from "react"
import { Link } from 'gatsby';
import { css } from '@emotion/core';
import styled from "@emotion/styled";
import Layout from '../components/layout';
import Card from '../components/card';
import CATEGORIES from '../../data/categories.json';
import BRANDS from '../../data/brands.json';

const CustomCard = styled(Card)`
  box-sizing: border-box;
  width: calc(50% - 0.5rem);

  &:last-of-type {
    margin-left: 1rem;
  }

  @media (max-width: 800px) {
    width: 100%;

    &:last-of-type {
      margin: 1rem 0 0 0;
    }
  }
`;

const CustomLink = styled(Link)`
  border: none;
  color: #888;
  margin-bottom: 0.2rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  &:hover {
    color: #fc0;
  }
`;

export default () => {
  return (
    <Layout slug="">
      <section css={css`
        display: flex;
        flex-wrap: wrap;

        @media (max-width: 800px) {
          flex-direction: column;
        }
      `}>
        <div>
          <p>
            We are car care store with passion for auto detailing. We don't just sell the products,
            we provide support for your detailing problems and the product application. We can help you on
            product use and to achieve the desired results that a product should give.
            You can contact us via email or on WhatsApp or you can ask a question on
            our <a target="_blank" href="https://www.facebook.com/groups/pastewax">Facebook group</a>.
          </p>
          <p>
            We only sell 100% authentic products. Every image in our store is taken by us, and it is a real picture of the product. You will receive the exact same product that you see in the picture. You can pay with COD, Bank Transfer, or Easypaisa.
          </p>
        </div>

        <CustomCard className="layout">
          <h2 className="card-heading">Shop by Categories</h2>
          <div css={css`
            display: flex;
            flex-direction: column;
          `}>
            {Object.entries(CATEGORIES).map(([key, category]) => (
              <CustomLink key={key} to={`/category/${key}`}>{category}</CustomLink>
            ))}
          </div>
        </CustomCard>

        <CustomCard className="layout">
          <h2 className="card-heading">Shop by Brands</h2>
          <div css={css`
            display: flex;
            flex-direction: column;
          `}>
            {Object.entries(BRANDS).map(([key, brand]) => (
              <CustomLink key={key} to={`/brand/${key}`}>{brand.title}</CustomLink>
            ))}
          </div>
        </CustomCard>

      </section>
    </Layout>
  );
};
